import styled from 'styled-components';
import tw from 'twin.macro';

export const formSelectCustomStyle: any = {
  valueContainer: (base, { isDisabled }) => ({
    ...base,
    width: '100%',
    height: '3rem',
    paddingTop: '0.1rem',
    paddingBottom: '0.1rem',
    paddingLeft: '1.2rem',
    fontSize: '.875rem',
    fontWeight: '500',
    overflowY: 'scroll',
    background: isDisabled ? 'var(--gray-95)' : '',
  }),
  singleValue: (base, { isDisabled }) => ({
    ...base,
    color: isDisabled ? 'var(--gray-50)' : '',
  }),
  indicatorsContainer: (base, { isDisabled }) => ({
    ...base,
    background: isDisabled ? 'var(--gray-95)' : '',
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),
  container: (base) => ({
    ...base,
    width: '100%',
    height: '3rem',
  }),
  menu: (base) => ({
    ...base,
    fontSize: '.875rem',
  }),
  control: (base) => ({
    ...base,
    height: '3rem',
    borderColor: 'var(--gray-70)',
    overflow: 'hidden',
    borderRadius: '0.5rem',
  }),
  input: (base, { isDisabled }) => ({
    ...base,
    height: 'fit-content',
    padding: 0,
    margin: 0,
    color: isDisabled ? 'var(--gray-50)' : '',
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: '.875rem',
  }),
};

export const FormRadioStyle = styled.div`
  & {
    ${tw`w-full`}

    div.radio-wrap {
      ${tw`flex grid h-full gap-2`}
      flex-direction: ${(props) => (props.vertical ? 'column' : 'row')};
      width: ${(props) => (props.vertical ? 'fit-content' : '100%')};
      min-height: 3rem;
      grid-template-columns: ${(props) => `repeat(${props.radioCount}, 1fr)`};

      > label {
        flex: 1 1 auto;

        input[type='radio'] {
          display: none;
        }
        input[type='radio']:checked + span {
          ${tw`border-[2px] border-solid border-my-blue-60 !text-my-blue-60 bg-my-blue-98 text-label-lg-700`}
        }
        input[type='radio'][disabled]:checked + span {
          ${tw`border-[2px] border-solid border-my-blue-60 !text-my-blue-60 bg-my-blue-98 text-label-lg-700`}
        }
        input[type='radio'][disabled] + span {
          ${tw`cursor-default`}
        }

        > span {
          ${tw`flex items-center justify-center w-full h-full border border-solid cursor-pointer text-label-lg-500 text-my-gray-50 border-my-gray-70`};
          border-radius: 1.5rem;
          background: white;
        }
      }
    }

    div.long-radio {
      ${tw`flex flex-col w-full gap-3`}
      flex: 1;

      > label {
        ${tw`flex items-center w-full text-body-md-500`}

        > input {
          display: none;
          :checked ~ span {
            ${tw`text-body-md-700 text-my-blue-98 bg-my-blue-50 border-my-blue-50`}

            > svg {
              display: flex;
              margin-right: 0.25rem;
            }
          }
        }
        > span {
          display: flex;
          align-items: center;
          padding: 0.75rem 1rem;
          width: 100%;

          border: 1px solid var(--gray-70);
          border-radius: 0.5rem;
          cursor: pointer;
          > svg {
            display: none;
            margin-right: 0.25rem;
          }
        }
      }
    }

    div.normal-radio {
      ${tw`flex justify-start`}
      flex-grow: 0;
      flex-direction: ${(props) =>
        props.customStyles?.wrap?.flexDirection
          ? props.customStyles.wrap.flexDirection
          : 'row'};
      gap: ${(props) =>
        props.customStyles?.wrap?.gap ? props.customStyles.wrap.gap : '1rem'};

      > label {
        ${tw`flex items-center`}
        flex-grow: ${(props) =>
          props.customStyles?.label?.flexGrow
            ? props.customStyles.label.flexGrow
            : '1'};

        > input[type='radio'] {
          display: block;
          margin-right: 0.25rem;

          :checked ~ span {
            ${tw`text-my-gray-10`}
            border: none;
          }

          :disabled ~ span {
            ${tw`cursor-default text-my-gray-70`}
          }
        }

        > span {
          ${tw`text-my-gray-10 text-label-lg-500`}
          border: none;
        }
      }
    }

    div.square-radio {
      ${tw`flex grid w-full h-full gap-2`}
      min-height: 2.5rem;
      grid-template-columns: ${(props) => `repeat(${props.radioCount}, 1fr)`};

      > label {
        width: 100%;

        input[type='radio'] {
          display: none;
        }
        input[type='radio']:checked + span {
          ${tw`border-[2px] border-solid border-my-blue-60 !text-my-blue-60 bg-my-blue-98 text-label-lg-700`}
        }
        input[type='radio'][disabled]:checked + span {
          ${tw`border-[2px] border-solid border-my-blue-60 !text-my-blue-60 bg-my-blue-98 text-label-lg-700`}
        }
        input[type='radio'][disabled] + span {
          ${tw`cursor-default`}
        }

        > span {
          ${tw`flex items-center justify-center w-full h-full border border-solid cursor-pointer text-label-lg-500 text-my-gray-20 border-my-gray-70`};
          border-radius: 0.5rem;
          background: white;
        }
      }
    }
    div.big-square-radio {
      ${tw`flex grid w-full h-full gap-2`}
      grid-template-columns: ${(props) => `repeat(${props.radioCount}, 1fr)`};

      > label {
        width: 100%;

        input[type='radio'] {
          display: none;
        }
        input[type='radio']:checked + span {
          ${tw`border-[2px] border-solid border-my-blue-60 !text-my-blue-50 bg-my-blue-98 text-label-lg-700`}
        }
        input[type='radio'][disabled]:checked + span {
          ${tw`border-[2px] border-solid border-my-blue-60 !text-my-blue-50 bg-my-blue-98 text-label-lg-700`}
        }
        input[type='radio'][disabled] + span {
          ${tw`cursor-default`}
        }

        > span {
          ${tw`flex min-h-[6rem] flex-col gap-2 items-center justify-center w-full h-full p-1 text-center whitespace-pre-wrap border border-solid cursor-pointer text-label-lg-500 text-my-gray-50 border-my-gray-70`};
          border-radius: 0.5rem;
          background: white;
        }
      }
    }
  }
`;

export const FormDatePickerStyle = styled.div`
  & {
    .datepicker-calendar-container {
      height: 350px;
      background: transparent;
      right: 50% !important;
      transform: translateX(50%) !important;
    }
    ${tw`relative flex items-center w-full gap-1`}

    div div.react-datepicker-wrapper input {
      ${tw`h-[3rem] px-4 text-body-md-500 text-my-gray-10`}
      box-sizing: border-box;
    }
    div div.react-datepicker-wrapper input::placeholder {
      ${tw`text-body-md-500 text-my-gray-60`}
    }
    div:first-child div.react-datepicker-wrapper input {
      border: ${(props) =>
        props.focusInput === 'start'
          ? '2px solid var(--blue-50)'
          : '1px solid var(--gray-70)'};
    }

    div:nth-child(3) div.react-datepicker-wrapper input {
      border: ${(props) =>
        props.focusInput === 'end'
          ? '2px solid var(--blue-50)'
          : '1px solid var(--gray-70)'};
    }

    div.react-datepicker-wrapper {
      width: 100%;
      box-sizing: border-box;

      input {
        width: 100%;
        border-radius: 0.5rem;
      }

      svg {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translate(0, -50%);
      }
    }

    div.react-datepicker {
      ${tw`w-full p-4 border border-solid rounded-xl border-my-gray-70 bg-my-gray-100 z-[1] max-w-[20.5rem]`}
      box-shadow: 0px 8px 24px 0px rgba(25, 32, 41, 0.16);

      div.react-datepicker__triangle {
        display: none;
      }
      > span.react-datepicker__aria-live {
        display: none;
      }

      button.react-datepicker__navigation {
        ${tw`top-[1.25rem]`}
      }
      button.react-datepicker__navigation--previous {
        ${tw`left-[1.25rem]`}
      }
      button.react-datepicker__navigation--next {
        ${tw`right-[1.25rem]`}
      }

      div.react-datepicker__month-container {
        ${tw`w-full`}

        div.react-datepicker__header {
          ${tw`flex flex-col w-full p-0 border-none bg-my-gray-100`}

          div.react-datepicker__current-month {
            ${tw`mb-4`}
          }

          div.react-datepicker__day-names {
            ${tw`flex justify-between w-full`}
            div {
              ${tw`flex items-center justify-center w-10 w-full h-10 m-0 text-label-md-500 text-my-gray-30`}
            }
          }
        }

        div.react-datepicker__month {
          ${tw`flex flex-col pt-2 m-0 gap-[0.13rem]`}
          div.react-datepicker__week:first-child {
            gap: 0;
          }
          div.react-datepicker__week {
            ${tw`flex justify-between m-0`}
            div {
              ${tw`relative flex items-center justify-center w-10 h-10 p-1 m-0 text-label-md-500 text-my-gray-30`}
            }
            .react-datepicker__day:not(.react-datepicker__day--range-start, .react-datepicker__day--in-range, .react-datepicker__day--selecting-range-end):hover {
              ${tw`bg-transparent`}
            }
            div:not(.react-datepicker__day--disabled, .react-datepicker__day--range-start, .react-datepicker__day--selected):hover::after {
              content: '';
              ${tw`flex justify-center items-center w-10 h-10 absolute top-0 left-[50%] rounded-[1.25rem]`}
              transform: translate(-50%, 0);
              ${tw`border border-solid border-my-blue-50`}
            }
            div.react-datepicker__day:not(.react-datepicker__day--disabled) {
              ${tw`cursor-pointer`}
            }
            div.react-datepicker__day--disabled {
              ${tw`text-my-gray-70`}
            }
            div.react-datepicker__day--selected {
              ${tw`flex justify-center items-center w-10 h-10 bg-my-blue-50 rounded-[1.25rem] text-my-gray-100`}
            }
            div.react-datepicker__day--today:not(.react-datepicker__day--selecting-range-start, .react-datepicker__day--range-start, .react-datepicker__day--selecting-range-start:hover, .react-datepicker__day--range-start:hover, [class*='react-datepicker__day--in-range'], [class*='react-datepicker__day--in-selecting-range']) {
              ${tw`text-label-md-700 text-my-gray-00`}
            }
            [class*='day--keyboard-selected'] {
              ${tw`bg-transparent text-label-md-700 text-my-gray-10`}
            }
            [class*='react-datepicker__day--in-range'],
            [class*='react-datepicker__day--in-selecting-range'],
            [class*='react-datepicker__day--in-range']:hover,
            [class*='react-datepicker__day--in-selecting-range']:hover {
              ${tw`bg-my-gray-90 rounded-[0]`}
            }

            div.react-datepicker__day--selecting-range-start,
            div.react-datepicker__day--range-start,
            div.react-datepicker__day--selecting-range-start:hover,
            div.react-datepicker__day--range-start:hover {
              ${tw`relative rounded-l-[1.25rem] rounded-r-[0]`}
              background: linear-gradient(
                to right,
                transparent 0%,
                transparent 50%,
                var(--gray-90) 50%,
                var(--gray-90) 100%
              );
            }
            div.react-datepicker__day--selecting-range-end:not(.react-datepicker__day--selected),
            div.react-datepicker__day--range-end,
            div.react-datepicker__day--selecting-range-end:hover:not(.react-datepicker__day--selected),
            div.react-datepicker__day--range-end:hover {
              ${tw`relative bg-my-gray-90 rounded-l-[0] rounded-r-[1.25rem] border-none`}
            }
            div.react-datepicker__day--selecting-range-start::after,
            div.react-datepicker__day--range-start::after {
              content: '${(props) => props.startDate}';
            }
            div.react-datepicker__day--selecting-range-end::before,
            div.react-datepicker__day--range-end::before {
              content: '${(props) => props.endDate}';
            }
            div.react-datepicker__day--selecting-range-start::after,
            div.react-datepicker__day--range-start::after,
            div.react-datepicker__day--selecting-range-end::before,
            div.react-datepicker__day--range-end::before {
              ${tw`flex justify-center items-center w-10 h-10 absolute top-0 left-[50%] rounded-[1.25rem]`}
              transform: translate(-50%, 0);
            }
            div.react-datepicker__day--selecting-range-start::after,
            div.react-datepicker__day--range-start::after,
            div.react-datepicker__day--range-end::before {
              ${tw`bg-my-blue-50 text-my-gray-100`}
            }
            div.react-datepicker__day--selecting-range-end::before {
              ${tw`border border-solid border-my-blue-50`}
            }
            [aria-label*='일요일'] {
              ${tw`rounded-l-md`}
            }
            [aria-label*='토요일'] {
              ${tw`rounded-r-md`}
            }
            div.react-datepicker__day--outside-month {
              opacity: 0;
            }
            div.react-datepicker__day--range-start.react-datepicker__day--range-start {
              background-color: white;
            }
          }
        }
      }
    }
  }
`;

export const studentInfoFormSelectCustomStyle = {
  control: (base) => ({
    ...base,
    maxHeight: '4.875rem',
    overflow: 'auto',
    width: '100%',
    borderRadius: '0.5rem',
    padding: '0 0.75rem',
    gap: '0.25rem',
    minHeight: '2.5rem',
    fontSize: '0.875rem',
    fontWeight: '500',
    color: 'var(--gray-10)',
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: '0.875rem',
    fontWeight: '500',
    color: 'var(--gray-60)',
  }),
  option: (base, state) => ({
    ...base,
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    width: '100%',
    padding: '0.62rem 1rem',

    backgroundColor: state.isSelected ? 'var(--gray-80)' : '',
    color: state.isSelected ? 'inherit' : '',
    cursor: 'pointer',

    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    '&:hover': {
      backgroundColor: 'var(--gray-90)',
    },
    '&:active': {
      backgroundColor: 'var(--gray-80)',
    },
    '> span': {
      flexGrow: 1,
      color: 'var(--gray-50)',
      fontFamily: 'Pretendard',
      fontSize: '0.75rem',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: '0.875rem',

      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  }),
  container: (base) => ({
    ...base,
    borderRadius: '0.5rem',
    backgroundColor: 'var(--gray-100)',
    boxShadow: '0px 8px 24px 0px rgba(25, 32, 41, 0.16)',
    height: '40rem',
    width: '15rem',
  }),
  indicatorsContainer: (base) => ({
    ...base,
    height: '100%',
  }),
  menu: (base) => ({
    ...base,
    position: 'static',
    border: 'none',
    boxShadow: 'none',
    margin: '0',
    maxHeight: '',
    height: '31.5rem',
  }),
  menuList: (base) => ({
    ...base,
    display: 'flex',
    flexDirection: 'column',
    padding: '0',
    width: '100%',
    fontSize: '0.875rem',
    overflowX: 'hidden',
    maxHeight: '100%',
    height: '100%',
    '::-webkit-scrollbar': {
      display: 'block',
      width: '0.875rem',
      borderRadius: '0.25rem',
    },
    '::-webkit-scrollbar-track': {
      borderLeft: '1px solid var(--gray-80)',
      background: 'transparent',
    },
    '::-webkit-scrollbar-thumb': {
      background: 'var(--gray-70)',
      backgroundClip: 'padding-box',
      borderRadius: '0.5rem',
      border: '0.25rem solid transparent',
      minHeight: '2rem',
    },
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '0',
    '> div': {
      padding: '0',
      margin: '0',
    },
  }),
};

export const FormInputStyle = styled.div`
  & {
    ${tw`flex flex-col w-full gap-1`}
    input {
      ${tw`w-full h-12 px-4 py-3 rounded-lg text-body-md-500`}
      ${(props) =>
        props.disabled
          ? tw`bg-my-gray-95 text-my-gray-50 border-my-gray-80`
          : tw`bg-my-gray-100 border-my-gray-70`}
      ${(props) =>
        props.hasError
          ? tw`border-[2px] border-solid border-my-red-50`
          : tw`border border-solid`}
    }
    div {
      ${tw`pl-3 text-label-md-500 text-my-red-50`}
    }
  }
`;
export const FormPhoneInputStyle = styled.div`
  & {
    ${tw`relative w-full`}
    .react-tel-input {
      input {
        ${tw`w-full h-12 text-body-md-500`}
        border-color: var(--gray-70);
        border-radius: 0.5rem !important;
        padding-left: 4.5rem;
        padding-top: 0.3rem;
      }
    }
    .flag-dropdown {
      border-top-left-radius: 0.5rem !important;
      border-bottom-left-radius: 0.5rem !important;
      border-color: var(--gray-70);
      background-color: var(--gray-100);
      .selected-flag {
        padding-left: 1rem;
        padding-right: 0.75rem;
        width: 3.75rem;
        border-radius: 0.5rem !important;
        .arrow {
          left: 1.5rem;
        }
      }
    }
    .error-message {
      ${tw`absolute pt-1 text-label-sm-500 text-my-red-50`}
    }
  }
`;
